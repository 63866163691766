import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Seo from '../components/SEO';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Logo from '../svg/outlineLogo.svg';
import Organic from '../svg/organic.svg';
import Timeless from '../svg/timeless.svg';
import Waste from '../svg/waste.svg';
import Description from '../svg/desc.svg';

import Future from '../components/Future.js';

function AboutPage() {
  const image = useStaticQuery(graphql`
    query {
      about: file(relativePath: { eq: "about.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO]
            transformOptions: { fit: COVER }
          )
        }
      }
    }
  `);

  return (
    <section className="mt-16 text-primary md:mt-20 lg:mt-12">
      <Seo pageTitle="about us" />
      <div className="flex flex-col justify-center flex-grow border lg:flex-row border-primary">
        <div className="flex flex-col justify-between p-8 lg:w-2/4 lg:p-16">
          <div className="py-16">
            <h1 className="pb-4 font-serif text-5xl font-bold md:text-5xl">
              about us
            </h1>

            <p className="max-w-lg font-sans text-base ">
              deadnice, a Newcastle—London based company creating design—led,
              environmentally sound clothing. <br />
              <br />
              formed to stitch together contemporary culture and sustainable
              ideologies, through a multidisciplinary approach inspired by
              product design. <br />
              <br />
              with a clear design DNA, we're in the business of creating unique
              collections that form a well-refined wardrobe that transcends
              trends. <br />
              <br />
              <br />
              <b>designing a better future.</b>
            </p>
          </div>
          <div className="flex flex-col justify-end pt-8 pb-2 h-2/4">
            <Logo />
          </div>
        </div>
        <div className="border-t border-primary lg:w-2/4 md:border-l-0 lg:border-t-0 lg:border-l explain">
          <GatsbyImage
            image={getImage(image.about)}
            className="h-full"
            alt="about us"
          />
        </div>
      </div>
      <div className="flex flex-row items-center justify-center p-8 -mt-px md:p-24 lg:p-40 border-primary explain ">
        <Description />
      </div>

      <div className="-mt-px border border-primary">
        <h1 className="py-8 text-4xl font-bold text-center">values</h1>
      </div>

      <section className="flex flex-col justify-center flex-auto flex-grow -mt-px border border-primary">
        <section className="flex flex-col items-baseline w-full sm:flex-row">
          <div className="flex flex-col items-center self-center px-8 py-16 sm:w-2/6 md:w-2/6 lg:w-2/6 md:py-16 md:px-4">
            <Organic alt="organic plants" />
            <div className="px-6 text-center">
              <h2 className="pt-8 pb-2 text-2xl font-bold">sustainable</h2>
              <p className="max-w-sm text-sm opacity-80">
                responsibly sourced materials, matched with eco-friendly
                printing process to give us a clean and sustainable production
                line
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center self-center px-8 py-16 border-0 sm:w-2/6 md:w-2/6 lg:w-2/6 md:py-24 md:px-4 sm:border-r sm:border-l border-primary">
            <Waste alt="stop sign with waste bins" width="50%" />
            <div className="px-6 text-center">
              <h2 className="pt-8 pb-2 text-2xl font-bold">minimal waste</h2>
              <p className="max-w-sm text-sm opacity-80">
                our production runs are kept to small batches so we can ensure
                high quality and so we don’t create unnecessary waste
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center self-center px-8 py-16 sm:w-2/6 md:w-2/6 lg:w-2/6 md:py-16 md:px-4">
            <Timeless alt="organic plants" width="50%" />
            <div className="px-6 text-center">
              <h2 className="pt-8 pb-2 text-2xl font-bold">timeless</h2>
              <p className="max-w-sm text-sm opacity-80">
                we believe in making clothes not just for the season, nor the
                year — we want to offer a line that lasts the test of time.
              </p>
            </div>
          </div>
        </section>
        <Future />
      </section>

      <section>
        <h1 className="h-screen py-64 mt-32 text-4xl font-bold text-center">
          thank you for joining <br /> us on this journey
        </h1>
      </section>
    </section>
  );
}

export default AboutPage;
